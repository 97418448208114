<script setup lang="ts">
import { VueInstance } from '@vueuse/core'
import { Field, ErrorMessage } from 'vee-validate'
import IconEyeOn from '@/assets/icons/eye-on.svg'
import IconEyeOff from '@/assets/icons/eye-off.svg'

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  placeholder: {
    type: String,
    required: false,
  },
  isFocused: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    required: true,
  },
  rules: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  validateOnBlur: {
    type: Boolean,
    default: true,
  },
  defaultValue: {
    type: String,
    default: '',
  },
})

const modelValue = ref(props.defaultValue)

const _type = ref(props.type)
const field = ref<VueInstance>()

const toggleType = () => {
  _type.value === 'password'
    ? (_type.value = 'text')
    : (_type.value = 'password')
}

const isEmail = computed(
  () => props.type === 'email' || props.rules.match(/email/),
)

if (isEmail.value) {
  useValidFieldTracker(field, modelValue, props.rules)
}

const emit = defineEmits(['update:modelValue'])

const handleUpdateValue = (value) => {
  emit('update:modelValue', value)
}

onMounted(() => {
  props.isFocused && field.value.$el.focus()

  if (props.defaultValue) {
    handleUpdateValue(props.defaultValue)
  }
})
</script>

<template>
  <div>
    <label class="mb-1 block">
      <span class="mb-1 block text-xs font-semibold">
        {{ label }} <span v-if="required" class="text-error">*</span>
      </span>

      <div class="relative">
        <Field
          ref="field"
          :name="name"
          :rules="rules"
          class="form-input-outline form-input w-full rounded border border-s-600 py-1 px-3 placeholder-s-400"
          :class="{ 'pr-12': type === 'password' }"
          :type="_type"
          :placeholder="placeholder"
          :validate-on-blur="validateOnBlur"
          :validate-on-input="!isEmail"
          :label="label"
          :value="modelValue"
          @update:model-value="handleUpdateValue"
        />

        <!-- Password Toggle -->
        <div
          v-if="type === 'password'"
          class="absolute right-1 top-1/2 -translate-y-1/2 text-s-900"
        >
          <div
            class="flex h-7 w-7 cursor-pointer items-center"
            @click="toggleType"
          >
            <IconEyeOff v-show="_type === 'password'" />
            <IconEyeOn v-show="_type === 'text'" />
          </div>
        </div>
      </div>
    </label>

    <div class="h-4">
      <ErrorMessage class="block text-xxs text-error" :name="name" />
    </div>
  </div>
</template>
